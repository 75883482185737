<template>
    <div>
        <b-navbar v-if="showNavbar" toggleable="lg" type="light" variant="default" class="shadow-sm py-lg-0">
            <b-navbar-brand to="/" class="nav-title">
                <img class="nav-icon" src="@/assets/icons/Logo-BeneficiarioFinal-V.png" alt="Icon" />
                <span id="tooltip-target">
                    2.004
                    <b-tooltip target="tooltip-target" triggers="hover">
                        2.004240821
                    </b-tooltip>
                </span>

            </b-navbar-brand>
            <b-navbar-toggle target="nav-collapse" class="mx-4">
                <template #default="{ expanded }">
                    <b-icon v-if="expanded" icon="chevron-bar-up"></b-icon>
                    <b-icon v-else icon="chevron-bar-down"></b-icon>
                </template>
            </b-navbar-toggle>
            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav class="portal-nav ms-auto me-xl-5">
                    <b-nav-item v-if="showDashboard" class="nav-menu-text" :to="{ name: 'dashboard' }">
                        <b-icon-graph-up class="me-2" scale="0.8"></b-icon-graph-up>{{ $t("navbar.dashboard") }}
                    </b-nav-item>

                    <b-nav-item v-if="showProcedures" class="nav-menu-text" :class="{
                        'router-link-exact-active':
                            $route.name == 'procedure-details' ||
                            $route.name == 'risk-report' ||
                            $route.name ==
                            'final-beneficiary-request-start',
                    }" :to="{ name: 'procedures' }"><b-icon-clipboard-check class="me-2"
                            scale="0.8"></b-icon-clipboard-check>{{
                                $t("navbar.procedures") }}</b-nav-item>

                    <b-nav-item v-if="showLegalEntities || enabledCriteria" class="nav-menu-text" :class="{
                        'router-link-exact-active':
                            $route.name == 'new-legal-entity' ||
                            $route.name == 'legal-entity',
                    }" :to="{ name: 'legal-entities' }">
                        <b-icon-people class="me-2" scale="0.8"></b-icon-people>{{ $t("navbar.legal-persons") }}
                    </b-nav-item>

                    <b-nav-item v-if="showSuperintendences" class="nav-menu-text" :class="{
                        'router-link-exact-active':
                            $route.name ===
                            'send-superintendence-invitation',
                    }" :to="{ name: 'superintendences' }">
                        <b-icon-briefcase class="me-2" scale="0.8"></b-icon-briefcase>
                        {{ $t("navbar.superintendence") }}
                    </b-nav-item>

                    <b-nav-item v-if="showSuperintendences || showRiskManagement" class="nav-menu-text" :class="{
                        'router-link-exact-active':
                            $route.name ===
                            'risk-management',
                    }" :to="{ name: 'risk-management' }">
                        <b-icon-grip-horizontal class="me-2" scale="0.8"></b-icon-grip-horizontal>
                        {{ $t("navbar.risk-management") }}
                    </b-nav-item>

                    <b-nav-item v-if="showLawFirms" class="nav-menu-text" :class="{
                        'router-link-exact-active':
                            $route.name === 'add-law-firm' ||
                            $route.name === 'law-firm',
                    }" :to="{ name: 'law-firms' }"><b-icon-people-fill class="me-2" scale="0.8"></b-icon-people-fill>
                        {{ $t("navbar.law-firms") }}</b-nav-item>

                    <b-nav-item v-if="showResidentAgents" class="nav-menu-text" :class="{
                        'router-link-exact-active':
                            $route.name ===
                            'send-resident-agent-invitation',
                    }" :to="{ name: 'resident-agent-list' }"><b-icon-person class="me-2" scale="0.8"></b-icon-person>
                        {{ $t("navbar.resident-agents") }}</b-nav-item>

                    <b-nav-item v-if="showCompetentAuthorities" class="nav-menu-text" :class="{
                        'router-link-exact-active':
                            $route.name === 'add-competent-authority' ||
                            $route.name === 'competent-authority',
                    }" :to="{ name: 'competent-authorities' }"><b-icon-building class="me-2"
                            scale="0.8"></b-icon-building>
                        {{ $t("navbar.competent-authorities") }}</b-nav-item>

                    <b-nav-item v-if="showOfficials" class="nav-menu-text" :class="{
                        'router-link-exact-active':
                            $route.name === 'send-official-invitation',
                    }" :to="{ name: 'officials' }"><b-icon-person-square class="me-2"
                            scale="0.8"></b-icon-person-square>
                        {{ $t("navbar.officials") }}</b-nav-item>

                    <b-nav-item v-if="showResidentAgent" class="nav-menu-text" :to="{ name: 'resident-agent-profile' }">
                        <b-icon-person-circle class="me-2" scale="0.8"></b-icon-person-circle>
                        {{ $t("navbar.resident-agent") }}
                    </b-nav-item>

                    <b-nav-item-dropdown v-if="showEvents" class="nav-menu-text mt-1" right>
                        <template slot="button-content">
                            <b-icon-calendar2-event class="me-2" scale="0.8"></b-icon-calendar2-event><br>
                            <span>Reportería</span>
                        </template>
                        <b-dropdown-item class="nav-menu-text" :to="{ name: 'events' }">
                            <b-icon-calendar2-event class="me-2" scale="0.8"></b-icon-calendar2-event>
                            {{ $t("navbar.events") }}
                        </b-dropdown-item>
                        <b-dropdown-item class="nav-menu-text" :to="{ name: 'variable-information' }">
                            <b-icon-calendar2-event class="me-2" scale="0.8"></b-icon-calendar2-event>
                            {{ $t("navbar.variableInformation") }}
                        </b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
            <b-collapse id="main-nav-collapse" is-nav>
                <Sso />
            </b-collapse>
        </b-navbar>
        <h6 v-if="enabledCriteria" class="pb-2 mt-5 text-center text-danger">
            SU FIRMA HA SIDO INACTIVADA, PARA SOLICITAR ACTIVACIÓN PÓNGASE EN CONTACTO CON UN ADMINISTRADOR DEL SITIO
        </h6>
        <AlertManualNotificationLawFirm :isResidentAgent="isResidentAgent || enabledCriteria" />
        <AlertDynamicNotificationLawFirm :isResidentAgent="isResidentAgent || enabledCriteria" />
        <AlertAutomaticNotificationLawFirm :isResidentAgent="isResidentAgent || enabledCriteria" />
    </div>
</template>

<script>
import { mapGetters } from "vuex";

import Sso from "@/components/external-components/Sso.vue";
import AlertManualNotificationLawFirm from "@/components/alerts/modals/AlertManualNotificationLawFirm.vue";
import AlertDynamicNotificationLawFirm from "@/components/alerts/modals/AlertDynamicNotificationLawFirm.vue";
import AlertAutomaticNotificationLawFirm from "@/components/alerts/modals/AlertAutomaticNotificationLawFirm.vue";

export default {
    name: "nav-bar",
    components: {
        Sso,
        AlertManualNotificationLawFirm,
        AlertDynamicNotificationLawFirm,
        AlertAutomaticNotificationLawFirm
    },
    computed: {
        ...mapGetters("oidcStore", ["oidcIsAuthenticated"]),
        ...mapGetters("application", [
            "isResidentAgent",
            "isSuperintendence",
            "isOfficial",
            "allowSuperintendenceCreateRA",
            "allowResidentAgentCreateRoles",
            "allowResidentAgentAdminFinalBeneficiaries",
            "allowOfficialStartProcedure",
            "allowSuperintendenceLastProcedureValidation",
            "allowSuperintendenceFirstProcedureValidation",
            "allowSuperintendenceCreateCA",
            "allowOfficialCreateAndAsignRoles",
            "allowSuperintendenceLogs",
            "allowSuperintendenceAsignRoles",
            "allowSuperintendenceReadLawyersFirms",
            "allowSuperintendenceCurActivation",
            "allowSuperintendenceCurDeactivation",
            "getEnabledCriteria",
            "allowSuperintendenceRiskManagement"
        ]),
        showNavbar() {
            let meta = this.$route.meta;
            return meta.noNavbar !== true;
        },
        showDashboard() {
            return (
                this.isResidentAgent ||
                this.isSuperintendence ||
                this.isOfficial
            );
        },
        showLegalEntities() {
            return this.allowResidentAgentAdminFinalBeneficiaries;
        },
        showRequests() {
            return this.isSuperintendence || this.isOfficial;
        },
        showProcedures() {
            return this.allowSuperintendenceLastProcedureValidation || this.allowSuperintendenceFirstProcedureValidation || this.allowOfficialStartProcedure;
        },
        showSuperintendences() {
            return this.allowSuperintendenceAsignRoles;
        },
        showLawFirms() {
            return this.allowSuperintendenceCreateRA || this.allowSuperintendenceReadLawyersFirms || this.allowSuperintendenceCurActivation || this.allowSuperintendenceCurDeactivation;
        },
        showResidentAgents() {
            return this.allowSuperintendenceCreateRA || this.allowResidentAgentCreateRoles;
        },
        showResidentAgent() {
            return this.isResidentAgent;
        },
        showCompetentAuthorities() {
            return this.allowSuperintendenceCreateCA;
        },
        showOfficials() {
            return this.allowSuperintendenceCreateCA || this.allowOfficialCreateAndAsignRoles;
        },
        showEvents() {
            return this.allowSuperintendenceLogs;
        },
        enabledCriteria() {
            return this.getEnabledCriteria;
        },
        showRiskManagement() {
            return this.allowSuperintendenceRiskManagement;
        }
    },
};
</script>

<style scoped>
.nav-icon {
    width: 35x;
    height: 35px;
}

.nav-title {
    font-size: 18px;
    font-weight: 500;
    color: #406892 !important;
    margin-left: 1.5rem !important;
}

.nav-menu-text {
    font-weight: 500;
}

.portal-nav .nav-link {
    padding: 0.8rem 1.5rem;
}

.portal-nav .router-link-exact-active {
    color: rgba(0, 0, 0, 0.55) !important;
    background-color: #dbe9f5;
    border-bottom: 4px solid #597fa4;
}

@media (min-width: 1440px) {
    .nav-menu-text {
        margin-right: 0.25rem;
    }
}

/*toggler*/
.navbar-toggler {
    padding: 0.25rem 0.5rem;
    border-radius: 0;
    font-size: unset;
    background-color: #dbe9f5;
}
</style>
